import React, {useEffect} from 'react'
import './footer.css'
import video2 from '../../Assets/video (2).mp4'
import {FiSend} from 'react-icons/fi'
import {SiYourtraveldottv} from 'react-icons/si'
import {AiFillYoutube} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {FaTripadvisor} from 'react-icons/fa'
import {FiChevronRight} from 'react-icons/fi'

import Aos from 'aos'
import'aos/dist/aos.css'

const Footer = () => {
    // lets create a react hook to add a scroll animation...
    useEffect(()=>{
      Aos.init({duration: 2000})
    }, [])



  return (
    <section className='footer'>
      <div className="videoDiv">
        <video src={video2} loop autoPlay muted type="video/mp4"></video>
      </div>

      <div className="secContent container">
        <div className="contactDiv flex">
          <div data-Aos="fade-up" className="text">
            <small>KEEP IN TOUCH</small>
            <h2>Travel with us</h2>
          </div>

          <div className="inputDiv flex">
            <input data-Aos="fade-up" type="text" placeholder='Enter Email Address' />
            <button data-Aos="fade-up" className='btn flex' type='submit'>
              SEND <FiSend className="icon"/>
            </button>
          </div>
        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div className="logoDiv">
              <a href="#" className='logo flex'>
              <SiYourtraveldottv className= "icon"/> Travel.
              </a>
            </div>

          <div data-Aos="fade-up" className="footerParagraph">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin commodo sagittis iaculis. In egestas, enim et mattis varius, ligula est varius lectus, id ornare eros lectus eget felis.
          </div>

          <div data-Aos="fade-up" className="footerSocials">
            <AiOutlineTwitter className="icon"/>
            <AiFillYoutube className="icon"/>
            <AiFillInstagram className="icon"/>
            <FaTripadvisor className="icon"/>
          </div>
          </div>

          <div className="footerLinks grid">
            {/*Group One*/}
            <div data-Aos="fade-up" data-Aos-duration="3000" className="linkGroup">
              <span className="groupTitle">
                OUR AGENCY
              </span>

              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Services
              </li>

              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Insurance
              </li> 
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Agency
              </li>
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Tourism
              </li> 
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Payment
              </li>
            </div>

            {/*Group two*/}
            <div data-Aos="fade-up" data-Aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
                PARTNERS
              </span>

              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Bookings
              </li>

              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Rentcars
              </li> 
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                HostelWorld
              </li>
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Trivago
              </li> 
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                TripAdvisor
              </li>
            </div>

            {/*Group three*/}
            <div data-Aos="fade-up" data-Aos-duration="5000" className="linkGroup">
              <span className="groupTitle">
                LAST MINUTE
              </span>

              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                London
              </li>

              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                California
              </li> 
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Indonesia
              </li>
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Europe
              </li> 
              
              <li className="footerList flex">
                <FiChevronRight className="icon"/>
                Oceania
              </li>
            </div>

          </div>

          <div className="footerDiv flex">
            <small>BEST TRAVEL WEBSITE THEME</small>
            <small>COPYRIGHTS RESERVED - ROMUALD.K 2022</small>
          </div>

        </div>
      </div>

    </section>
  )
}

export default Footer